<template>
  <b-modal
      id="edit-store-modal"
      ref="edit-store-modal"
      cancel-variant="outline-secondary"
      ok-title="Kaydet"
      @ok="updateStore"
      cancel-title="Kapat"
      title="Mağaza Ayarları Düzenleme"
      size="lg"
  >
    <validation-observer ref="simpleRules">
      <form @submit.prevent>
        <b-tabs justified pills>
          <b-tab title="API Ayarları (HB)" v-if="store.marketplace_code=='HB' && store.params">
            <b-card-text>
              <b-form-group label="Mağaza ID" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.merchantId"/>
              </b-form-group>
              <b-form-group label="API Kullanıcı" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.user"/>
              </b-form-group>
              <b-form-group label="API Şifresi" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.pass"/>
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="API Ayarları (TY)" v-if="store.marketplace_code=='TY' && store.params">
            <b-card-text>
              <b-form-group label="Satıcı Id" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.sellerId"/>
              </b-form-group>
              <b-form-group label="API Key" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.apiKey"/>
              </b-form-group>
              <b-form-group label="API Secret" size="sm">
                <b-form-input type="text" size="sm" v-model="store.params.apiSecret"/>
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Sabit Giderler">
            <b-card-text>
              <b-form-group label-cols="4" label="Sabit Komisyon (%)" size="sm">
                <validation-provider
                    #default="{ errors }"
                    name="commission"
                    rules="required"
                >
                  <b-form-input
                      type="text"
                      size="sm"
                      v-model="store.commission"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label-cols="4" label="Sabit Gider (₺)" size="sm">
                <b-form-input type="text" size="sm" v-model="store.fixed_cost"/>
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Öncelik Sırası">
            <b-card-text>
              <draggable v-model="store.priorities" tag="ul" class="list-group list-group-flush cursor-move">
                <b-list-group-item v-for="(item, index) in store.priorities" :key="index" tag="li">
                  {{ index + 1 }}. {{ item.key }}
                </b-list-group-item>
              </draggable>
            </b-card-text>
          </b-tab>
          <b-tab title="Şartlar">
            <b-card-text>
              <b-form-group label-cols="4" label="Minimun Stok (Adet)" size="sm" v-if="store.conditions">
                <b-form-input type="text" size="sm" v-model="store.conditions.minStock"/>
              </b-form-group>
              <b-form-group label-cols="4" label="Maksimum Termin (Gün)" size="sm" v-if="store.conditions">
                <b-form-input type="text" size="sm" v-model="store.conditions.maxDeadline"/>
              </b-form-group>
            </b-card-text>
          </b-tab>
          <b-tab title="Karlılık Baremleri">
            <b-card-text>
              <b-row>
                <b-col cols="10">
                  <b-input-group>
                    <b-form-input type="text" value="Mak Alış" disabled/>
                    <b-form-input type="text" value="Min Kar" disabled/>
                    <b-form-input type="text" value="Mak Kar" disabled/>
                    <b-form-input type="text" value="Sabit Kar" disabled/>
                  </b-input-group>
                </b-col>
                <b-col cols="1">
                  Yönetim
                </b-col>
              </b-row>
              <Scale v-for="(scale,index) in store.scales"
                     :key="index"
                     :store-id="store.id"
                     :index="index"
                     :scale="scale"></Scale>
              <b-row>
                <b-col>
                  <b-button
                      size="sm"
                      variant="success"
                      @click="addScale"
                  >Yeni Ekle
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab title="Kargo Baramleri">
            <b-card-text>
              <b-row>
                <b-col cols="10">
                  <b-input-group>
                    <b-form-input type="text" value="Mak Satış" disabled/>
                    <b-form-input type="text" value="Fiyat" disabled/>
                  </b-input-group>
                </b-col>
                <b-col cols="1">
                  Yönetim
                </b-col>
              </b-row>
              <ShippingScale
                  v-for="(scale,index) in store.shipping_scales"
                  :key="index"
                  :store-id="store.id"
                  :index="index"
                  :scale="scale"
              ></ShippingScale>
              <b-row>
                <b-col>
                  <b-button
                      size="sm"
                      variant="success"
                      @click="addShippingScale"
                  >Yeni Ekle
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
          <b-tab title="Kargo Desileri">
            <b-card-text>
              <b-row>
                <b-col cols="10">
                  <b-input-group>
                    <b-form-input type="text" value="Desi" disabled/>
                    <b-form-input type="text" value="Fiyat" disabled/>
                  </b-input-group>
                </b-col>
                <b-col cols="1">
                  Yönetim
                </b-col>
              </b-row>
              <DesiScale
                  v-for="(scale,index) in store.desi_scales"
                  :key="index"
                  :store-id="store.id"
                  :index="index"
                  :scale="scale"
              ></DesiScale>
              <b-row>
                <b-col>
                  <b-button
                      size="sm"
                      variant="success"
                      @click="addDesiScale"
                  >Yeni Ekle
                  </b-button>
                </b-col>
              </b-row>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import Scale from "@/views/store/Scale";
import ShippingScale from "@/views/store/ShippingScale";
import draggable from 'vuedraggable'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, confirmed, password} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DesiScale from "@/views/store/DesiScale";

export default {
  name: "EditStore",
  components: {
    Scale, ShippingScale, draggable, ValidationProvider, ValidationObserver, DesiScale
  },
  data: () => ({}),
  computed: {
    ...mapGetters('store', {store: '_selectedEditStore'})
  },
  methods: {
    updateStore() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('store/edit', this.store)
              .then(props => {
                this.$toast({
                  component: ToastificationContent,
                  props
                })
              })
        }
      });
    },
    addShippingScale() {
      this.$store.commit('store/addaddShippingScale', this.store.id)
    },
    addDesiScale() {
      this.$store.commit('store/addDesiScale', this.store.id)
    },
    addScale() {
      this.$store.commit('store/addScale', this.store.id)
    }

  },

}
</script>

<style scoped>

</style>
