<template>
  <b-modal
      id="edit-store-supplier-params-modal"
      ref="edit-store-supplier-params-modal"
      cancel-variant="outline-secondary"
      ok-title="Kaydet"
      @show="showModal"
      @ok="update"
      cancel-title="Kapat"
      title="Mağaza/Tedarikçi Ayarları Düzenleme"
      size="xl"
  >
    <b-overlay
        :show="show"
        rounded="sm"
    >
      <h3>{{ store.marketplace_code }} - {{ store.store_name }}</h3>
      <table class="table table-sm">
        <thead>
        <tr>
          <th scope="col">Tedarikçi Adı</th>
          <th scope="col">Durum</th>
          <th scope="col">Min. Stok (Ad.)</th>
          <th scope="col">Min. Fiyat (₺)</th>
          <th scope="col">Maks. Termin (Gün)</th>
          <th scope="col">Terminler Stok</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(params) in _supplierParams" :key="params.id">
          <td>
            <b-form-input type="text" size="sm" v-model="params.supplier.name" disabled/>
          </td>
          <td>
            <span v-if="params.isActive">
               <b-icon icon="check-circle-fill" variant="success"/> &nbsp;
                <b-button
                    size="sm"
                    variant="light"
                    class="pt-0 pb-0"
                    @click="params.isActive = 0"
                >
                  <b-icon icon="x-circle-fill"/>
                  Pasif Et
                </b-button>
            </span>
            <span v-else>
              <b-icon icon="x-circle-fill" variant="danger"/> &nbsp;
                <b-button
                    size="sm"
                    variant="light"
                    class="pt-0 pb-0"
                    @click="params.isActive = 1"
                >
                <b-icon icon="x-circle-fill"/>
                Aktif Et
              </b-button>
            </span>
          </td>
          <td>
            <b-form-input type="number" size="sm" v-model="params.min_acceptable_stok"/>
          </td>
          <td>
            <b-form-input type="number" min="0.00" max="10000.00" step="0.01" size="sm"
                          v-model="params.min_acceptable_price"/>
          </td>
          <td>
            <b-form-input type="number" size="sm" v-model="params.max_deadline"/>
          </td>
          <td>
            <b-row>
              <b-col>
                <b-input-group>
                  <b-form-input type="text" size="sm" value="Mak.Stok (Adet)" disabled/>
                  <b-form-input type="text" size="sm" value="Termin (Gün)" disabled/>
                  <b-input-group-append>
                    <b-button size="sm" variant="outline-secondary" @click="deadlinesAddItem(params.supplier.id)">Ekle
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
            <b-row v-for="(deadline,index) in params.deadlines" :key="index">
              <b-col>
                <b-input-group>
                  <b-form-input type="number" size="sm" v-model="deadline.maxStock"
                                @blur="deadlinesItemOrder(params.supplier.id)"/>
                  <b-form-input type="number" size="sm" v-model="deadline.deadline"/>
                  <b-input-group-append>
                    <b-button size="sm" variant="outline-secondary"
                              @click="deadlinesDeleteItem(params.supplier.id,index)">Sil
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </td>
        </tr>
        </tbody>
      </table>

      <table class="table table-sm" v-if="suppliersDifference.length>0">
        <thead>
        <tr>
          <th scope="col">Tedarikçi Adı</th>
          <th scope="col">Ekle</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="supplier in suppliersDifference" :key="supplier.id">
          <td>
            <b-form-input size="sm" type="text" v-model="supplier.name" disabled/>
          </td>
          <td>
            <b-button @click="addSupplierParams(supplier)" variant="success" size="sm">Ekle</b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: 'EditStoreSupplirParams',
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters('store', {store: '_selectedEditStore'}),
    ...mapGetters('store', ['_supplierParams']),
    ...mapGetters('supplier', ['_suppliers']),
    suppliersDifference() {
      let supplierIds = [];
      this._supplierParams.forEach(params => {
        supplierIds.push(params.supplier_id);
      })
      let difference = this._suppliers.filter(supplier => !supplierIds.includes(supplier.id));
      return difference;
    }
  },
  methods: {
    update(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$store.dispatch('store/saveSupplierParams', this._supplierParams).then(props => {
        this.$toast({
          component: ToastificationContent,
          props
        })
      })
    },
    showModal(bvModalEvent) {
      this.$store.dispatch('store/supplierParams', this.store.id);
      this.$store.dispatch('supplier/suppliers');
    },
    addSupplierParams(supplier) {
      let data = {
        id: null,
        store_id: this.store.id,
        supplier_id: supplier.id,
        isActive: 1,
        min_acceptable_price: 0,
        min_acceptable_stok: 0,
        max_deadline: 10,
        deadlines: [{maxStock: 999999999, deadline: 10}],
        supplier: {id: supplier.id, name: supplier.name}
      }
      this.$store.commit('store/addSupplierParams', data);
    },
    deadlinesAddItem(supplierId) {
      this.$store.commit('store/addSupplierParamsDeadline', supplierId)
    },
    deadlinesDeleteItem(supplierId, itemId) {
      this.$store.commit('store/delSupplierParamsDeadline', {supplierId, itemId})
    },
    deadlinesItemOrder(supplierId) {
      this.$store.commit('store/orderSupplierParamsDeadline', supplierId)
    }
  },
}

</script>
