<template>
  <b-row>
    <b-col cols="10">
      <b-input-group size="sm">
        <b-form-input type="number" v-model="scale.desi"/>
        <b-form-input type="number" v-model="scale.price"/>
      </b-input-group>
    </b-col>
    <b-col cols="1">
      <b-button variant="danger" size="sm" @click="deleteScale(index)">
        <feather-icon icon="MinusCircleIcon"/>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "DesiScale",
  props: {
    index: {
      type: Number,
      required: true
    },
    storeId: {
      type: Number,
      required: true
    },
    scale: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteScale(index) {
      this.$store.commit('store/deleteDesiScale', {storeId: this.storeId, indexNo: index})
    }
  }
}
</script>

<style scoped>

</style>
