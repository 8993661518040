<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <b-overlay
            :show="show"
            rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <b-form @submit.prevent>
                <div class="row">
                  <div class="col-10">

                  </div>
                  <div class="col-2">
                    <b-button-group
                        size="sm"
                    >
                      <b-button
                          variant="primary"
                          @click="getAll"
                      >
                        Getir
                      </b-button>

                      <b-button
                          variant="success"
                          @click="showModal"
                      >
                        Yeni Ekle
                      </b-button>
                    </b-button-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </section>
</template>

<script>
import {BOverlay, BForm, BButtonGroup, BButton} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  name: "Search",
  components: {BOverlay, BForm, BButtonGroup, BButton},
  data() {
    return {
      show: false
    }
  },
  methods: {
    showModal() {
      alert('çalışma')
    },
    getAll() {
      this.show = true;
      this.$store.dispatch('store/stores')
          .then(res => {
            this.show = false;
            let count = res.length;
            let variant = 'success'
            if (!count) {
              variant = 'danger';
              count = 0;
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: count + ' adet mağaza bilgisi çekildi',
                variant,
                icon: 'ArrowDownCircleIcon',
              },
            })

          })
    }
  }
}
</script>

<style scoped>

</style>
