<template>
  <div class="row" id="table-hover-row">
    <div class="col-12">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
            <tr>
              <th>Mağaza Adı</th>
              <th>Parametre</th>
              <th>Yönet</th>
            </tr>
            </thead>
            <tbody>
            <Store
                v-for="store in _stores"
                :key="store.id"
                :store="store"
                :editStatus="true"
            >
            </Store>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Store from "@/views/store/Store";

export default {
  name: "Stores",
  components: {Store},
  computed: {
    ...mapGetters("store", ["_stores"])
  }
}
</script>

<style scoped>

</style>
