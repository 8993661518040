<template>
  <section>
    <!-- Basic Inputs start -->
    <Search></Search>
    <!-- Basic Inputs end -->

    <!-- Hoverable rows start -->
    <Stores></Stores>
    <!-- Hoverable rows end -->

    <EditStore></EditStore>
    <EditStoreSupplierParams/>
    <EditBrandCondition/>
  </section>
</template>

<script>
import Search from "@/views/store/Search";
import Stores from "@/views/store/Stores"
import EditStore from "@/views/store/EditStore";
import EditStoreSupplierParams from "@/views/store/EditStoreSupplierParams";
import EditBrandCondition from "@/views/store/EditBrandCondition.vue";

export default {
  name: "StoreHome",
  components: {Search, Stores, EditStore, EditStoreSupplierParams, EditBrandCondition}
}
</script>

<style scoped>

</style>
