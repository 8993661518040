<template>
  <tr>
    <td>
      <span v-if="editStatus">
      {{ store.id }}</span>. {{ store.store_name }} ({{ store.marketplace_code }})
    </td>
    <td>
      <b-input-group>
        <b-form-input type="text" v-model="store.refresh_token"/>
        <b-input-group-append>
          <b-button variant="outline-primary" @click="saveStoreRefreshToken">
            Kaydet
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </td>
    <td>
      <b-row>
        <b-col cols="4">
          <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-right"
              />
            </template>
            <b-dropdown-item @click="editStore">
              <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
              />
              <span>Düzenle</span>
            </b-dropdown-item>

            <b-dropdown-item @click="editStoreSupplierParams">
              <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
              />
              <span>Tedarikçi Parametre</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteStore">
              <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
              />
              <span>Sil</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-if="store.marketplace_code=='TY' && userData.roles.includes('admin')" variant="link"
                      toggle-class="text-decoration-none" no-caret
                      :disabled="isDisableBtn"
          >
            <template v-slot:button-content>
              <feather-icon icon="TruckIcon" size="16" class="text-body align-right"/>
            </template>
            <b-dropdown-item @click="setDeadline(store.id,0)"> Bugün Kargo</b-dropdown-item>
            <b-dropdown-item @click="setDeadline(store.id,1)"> Hızlı Kargo</b-dropdown-item>
            <b-dropdown-item @click="setDeadline(store.id,2)"> 2 Gün Kargo</b-dropdown-item>
            <b-dropdown-item @click="setDeadline(store.id,3)"> 3 Gün Kargo</b-dropdown-item>
            <b-dropdown-item @click="setDeadline(store.id,4)"> 4 Gün Kargo</b-dropdown-item>
            <b-dropdown-item @click="setDeadline(store.id,5)"> 5 Gün Kargo</b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="2">
          <b-badge
              v-if="store.brands_condition==='deny'"
              variant="danger"
              @click="editBrandCondition"
          >Yasaklı Liste
          </b-badge>
          <b-badge
              v-else
              variant="success"
              @click="editBrandCondition"
          >İzinli Liste
          </b-badge>
        </b-col>
        <b-col cols="6">
          <b-dropdown size="sm" text="Detay" variant="secondary" :disabled="deadlineMessages.length<1">
            <b-dropdown-item v-for="(message,index) in deadlineMessages" :key="index">
              {{ message }}
            </b-dropdown-item>
          </b-dropdown>
          <b-badge v-if="deadlineMessages.length>0">{{ deadlineMessages[0] }}</b-badge>
        </b-col>
      </b-row>


    </td>
  </tr>
</template>

<script>
import draggable from 'vuedraggable'
import Scale from "@/views/store/Scale";
import ShippingScale from "@/views/store/ShippingScale";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Vue2Filters from "vue2-filters";
import axios from "@/libs/axios";

export default {
  name: "Store",
  mixins: [Vue2Filters.mixin],
  components: {
    draggable,
    Scale,
    ShippingScale,
    ToastificationContent
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      paramStatus: false,
      show: false,
      isDisableBtn: false,
      deadlineMessages: [],
    }
  },
  props: {
    store: {
      type: Object,
      required: true
    },
    editStatus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    paramStatusType() {
      if (this.paramStatus) return 'text'
      return 'password'
    }
  },
  methods: {

    getDeadlineMessages(storeId) {
      axios.get('/trendyol/product/' + storeId + '/get-deadline/messages')
          .then(res => {
            console.log(res.data.messages)
            this.deadlineMessages = res.data.messages;
            if (!res.data.statu) {
              this.isDisableBtn = false;

              this.$swal.fire({
                icon: 'success',
                text: 'Bitti',
                title: 'B2',
              })
            } else {
              setTimeout(() => {
                this.getDeadlineMessages(storeId);
              }, 5000);
            }
          })
    },
    async setDeadline(storeId, day) {
      const {value: statu} = await this.$swal.fire({
        width: 800,
        title: 'Ürün Durumu Seçin',
        input: 'radio',
        inputOptions: {
          'all': 'Tümü',
          'ON_SALE': 'Satıştakiler',
          'OUT_OF_STOCK': 'Tükenenler',
          'REJECTED': 'Revize',
          'NOT_ON_SALE': 'Satışta Olmayanlar',
          'ACTION_NEEDED': 'Kilitli',
          'ARCHIVED': 'Arşiv',
        },
        inputValidator: (value) => {
          if (!value) {
            return 'Bir Seçim Yapmadınız!'
          }
        }
      })
      if (statu) {
        this.isDisableBtn = true;
        axios.get('/trendyol/product/' + storeId + '/set-deadline/' + day + '/' + statu)
            .then(res => {
              this.deadlineMessages = ['Başladı']
              setTimeout(() => {
                this.getDeadlineMessages(storeId);
              }, 2000);
              this.$swal.fire({
                icon: res.data.status,
                text: res.data.message,
                title: res.data.title,
              })
            })
      }

    },
    saveStoreRefreshToken() {
      this.show = true;
      this.$store.dispatch('store/edit', {id: this.store.id, refresh_token: this.store.refresh_token})
          .then(props => {
            this.show = false;
            this.$toast({
              component: ToastificationContent,
              props
            })
          })
    },
    editBrandCondition($event) {
      this.$root.$emit('set-store-id', this.store)
      this.$root.$emit('bv::show::modal', 'edit-brand-condition-modal', $event.target)
    },
    editStore($event) {
      this.$store.commit('store/selectedEditStore', this.store);
      this.$root.$emit('bv::show::modal', 'edit-store-modal', $event.target)
    },
    editStoreSupplierParams($event) {
      this.$store.commit('store/selectedEditStore', this.store);
      this.$root.$emit('bv::show::modal', 'edit-store-supplier-params-modal', $event.target)
    },
    deleteStore() {
      alert('Silemezsiniz')
    }
  }
}
</script>

<style scoped>

</style>
